<template>
    <div>
    <div class="curContainer" style="background: #fff;">
        <div width="100%">
            <el-row type="flex" justify="end"></el-row>
            <el-form inline :model="searchForm" label-width="140px" style="margin-top: 30px;">
                <el-form-item label="项目创建日期">
                    <el-date-picker
                    v-model="searchForm.dateArray"
                    type="daterange"
                    range-separator="至"
                    value-format="yyyy-M-d"
                    start-placeholder="年/月/日"
                    end-placeholder="年/月/日"
                    @change="onDateChange">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="选择项目">
                    <el-select v-model="searchForm.projectId" style="width:400px;" @change="onModelChange" placeholder="请选择">
                        <el-option
                        v-for="item in projectList"
                        :key="item.projectId"
                        :label="item.projectName"
                        :value="item.projectId"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="包含测试数据">
                    <el-checkbox v-model="searchForm.includeTest"></el-checkbox>
                </el-form-item>
<!-- 
                <el-form-item label="关键字">
                    <el-input v-model="searchForm.key" autocomplete="off"></el-input>
                </el-form-item> -->
                <el-button :disabled="searchForm.projectId == ''" @click="onAnalisys" type="primary">分析</el-button>
            </el-form>
            <el-tabs style="padding:10px" v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="项目分析" name="first">
                    <!-- <div v-if="rightArr.canExport" style="text-align: right;padding-right:10px;" v-show="searchForm.projectId">
                        <el-button type="primary" :loading="pptExporting" round @click="onPPTExport">导出PPT</el-button>
                    </div> -->
                    <div style="width:100%;">

                        <el-tabs style="padding:10px" v-model="activeMediaName" @tab-click="handleMediaClick">
                            <el-tab-pane v-for="(mItem,mIdx) in mediaList" :label="mItem.parentMedia" :name="mItem.parentMedia">
                            </el-tab-pane>
                        </el-tabs>

                        <div v-show="siweiSc" class="chart" style="width:100%;margin-top: 6px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="siweiSc" id="siweiSc" :obj-name="'siweiSc'" @onchange="onchange"/>                          
                        </div>
                        <div v-show="siweiPc" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="siweiPc" id="siweiPc" :obj-name="'siweiPc'" @onchange="onchange"/>                          
                        </div>
                        <div v-show="siweiQd" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="siweiQd" id="siweiQd" :obj-name="'siweiQd'" @onchange="onchange"/>                          
                        </div>
                        <div v-show="siweiZs" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="siweiZs" id="siweiZs" :obj-name="'siweiZs'" @onchange="onchange"/>                          
                        </div>
                        <div v-show="siweiNl" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="siweiNl" id="siweiNl" :obj-name="'siweiNl'" @onchange="onchange"/>                          
                        </div>
                        <div v-show="siweiJy" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="siweiJy" id="siweiJy" :obj-name="'siweiJy'" @onchange="onchange"/>                          
                        </div>
                        <div v-show="siweiQg" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="siweiQg" id="siweiQg" :obj-name="'siweiQg'" @onchange="onchange"/>                          
                        </div>
                        <div v-show="siweiZhishu" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="siweiZhishu" id="siweiZhishu" :obj-name="'siweiZhishu'" @onchange="onchange"/>                          
                        </div>

                        <div v-show="sanliXs" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="sanliXs" :obj-name="'sanliXs'" @onchange="onchange"/>                          
                        </div>
                        <div v-show="sanliPp" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="sanliPp" :obj-name="'sanliPp'" @onchange="onchange"/>                          
                        </div>
                        <div v-show="sanliYh" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="sanliYh" :obj-name="'sanliYh'" @onchange="onchange"/>                          
                        </div>
                        <div v-show="sanliZhishu" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="sanliZhishu" :obj-name="'sanliZhishu'" @onchange="onchange"/>                          
                        </div>

                        <div v-show="leixingGz" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="leixingGz" :obj-name="'leixingGz'" @onchange="onchange"/>                          
                        </div>
                        <div v-show="leixingJr" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="leixingJr" :obj-name="'leixingJr'" @onchange="onchange"/>                          
                        </div>
                        <div v-show="leixingXd" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="leixingXd" :obj-name="'leixingXd'" @onchange="onchange"/>                          
                        </div>
                        <div v-show="leixingZhishu" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="leixingZhishu" :obj-name="'leixingZhishu'" @onchange="onchange"/>                          
                        </div>

                        <div v-show="fenggeGz" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="fenggeGz" :obj-name="'fenggeGz'" @onchange="onchange"/>                          
                        </div>
                        <div v-show="fenggeJr" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="fenggeJr" :obj-name="'fenggeJr'" @onchange="onchange"/>                          
                        </div>
                        <div v-show="fenggeXd" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="fenggeXd" :obj-name="'fenggeXd'" @onchange="onchange"/>                          
                        </div>
                        <div v-show="fenggeZhishu" class="chart" style="width:100%;margin-top: 26px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <my-chart ref="fenggeZhishu" :obj-name="'fenggeZhishu'" @onchange="onchange"/>                          
                        </div>
                    </div>

                    
                </el-tab-pane>
                <el-tab-pane label="调研数据" name="second" style="background-color: #f6f6f6;">
                    <div v-if="rightArr.canExport" style="text-align: right;padding-right:10px;padding-top:0.5rem;" v-show="searchForm.projectId">
                        <el-button type="primary" :loading="excelExporting" round @click="onExport">导出汇总</el-button>
                        <el-button type="primary" :loading="excelExporting" round @click="onDetailExport">导出明细</el-button>
                        <el-button type="primary" :loading="spssExporting" round @click="onSpssExport">导出SPSS数据</el-button>
                    </div>
                    
                    <!-- <table class="my-table" style="width:90%;margin-top:1rem;">
                        <tr style="font-weight: bold;color: rgb(96, 98, 102);background-color: #e2f0ff;">
                            <td style="padding:0 7px;min-width:70px;text-align:center;">问题</td>
                            <td style="padding:0 7px;min-width:70px;text-align:center;">答案/分值</td>
                            <td style="padding:0 7px;min-width:70px;text-align:center;">列项</td>
                            <td style="padding:0 7px;min-width:70px;text-align:center;">数量(个)</td>
                            <td style="padding:0 7px;min-width:70px;text-align:center;">占比(%)</td>
                        </tr>
                        <tr v-for="(itemrow,indexrow) in ProjectAnalisysV5[0]" :key="indexrow">
                            <td style="padding:0 7px;min-width:70px;text-align:center;">{{ itemrow.问题 }}</td>
                            <td style="padding:0 7px;min-width:70px;text-align:center;">{{ itemrow.答案或分值 }}</td>
                            <td style="padding:0 7px;min-width:70px;text-align:center;">{{ itemrow.列项 }}</td>
                            <td style="padding:0 7px;min-width:70px;text-align:center;">{{ itemrow.数量}}</td>
                            <td style="padding:0 7px;min-width:70px;text-align:center;">{{ itemrow.占比 }}</td>
                        </tr>
                    </table> -->
                    <common-table  ref="tableObj" :data="ProjectAnalisysV5[0]" border :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                        style="width: 100%;margin-top: 10px;" :sizePrePage="9999" stripe>
                        <el-table-column prop="问题" align="center" label="问题" ></el-table-column>
                        <el-table-column prop="答案或分值" align="center" label="答案/分值" ></el-table-column>
                        <el-table-column prop="列项" align="center" label="列项" ></el-table-column>
                        <el-table-column prop="数量" align="center" label="数量(个)" ></el-table-column>
                        <el-table-column prop="占比" align="center" label="占比(%)" ></el-table-column>
                    </common-table>
                </el-tab-pane>
            </el-tabs>
        </div>

    </div>
</div>
</template>
<script>
import { mapGetters } from "vuex"; 
import {getLatestMonthDateArrayByDate} from "@/utils/time";
import CommonTable from "@/components/CommonTable";
// import eachquestion from '@/views/Survey/bkground/component/eachQuestion';
import allQuestions from '@/views/Survey/bkground/component/allQuestions';
import {getToken} from "@/utils/auth";
import myChart from "@/components/myChart";
import myChartSetBarColor from "@/components/myChartSetBarColor";
import {UserQuesExport, ProjectExportPPT, GetUserAnswer,CreateSPSSData} from "@/api/survey";
import {UserRoleModuleDetail} from "@/api/user";

export default {
    components: {
        CommonTable,allQuestions, myChart, myChartSetBarColor
    },
    computed: {...mapGetters(['projectList','ProjectAnalisysV2','ProjectAnalisysV5'])},
    data() {
        return {
            isPlan1: true,
            searchForm: {
                dateArray: getLatestMonthDateArrayByDate({date:new Date(),delayNum:1, formatValue: 'yyyy-MM-dd'}),
                projectId: '',
                key: '',
                includeTest:false
            },
            isEdit: false,
            editForm: {
                modelName: '',
                questions: [],                
            },
            activeName: 'first',
            excelExporting: false,
            pptExporting: false,
            spssExporting:false,
            period:'',
            type:'',
            tableData:[],

            graphData:[],
            siweiSc:false,
            siweiPc:false,
            siweiQd:false,
            siweiZs:false,
            siweiNl:false,
            siweiJy:false,
            siweiQg:false,
            siweiZhishu:false,
            sanliXs:false,
            sanliPp:false,
            sanliYh:false,
            sanliZhishu:false,
            leixingGz:false,
            leixingJr:false,
            leixingXd:false,
            leixingZhishu:false,
            fenggeGz:false,
            fenggeJr:false,
            fenggeXd:false,
            fenggeZhishu:false,

            rightArr:{},

            mediaList:[],
            curMedia:{},
        };
    },
    async mounted() {
		console.log('1a')
        var params = {userId:getToken(),moduleId:4}
        await UserRoleModuleDetail(params).then((res)=>{
            // console.log('data',res)
            this.rightArr = res.data[0]
        })

        await this.$store.dispatch('survey/GetProvinceCityList',{userId:getToken(), typeId:0 }).then(()=>{  })
        await this.onSearch();          
    },
    methods: {
        async onSearch() {
            const {dateArray: [sYM, eYM],key,} = this.searchForm;
            var sDate = new Date(sYM).toLocaleDateString();
            var eDate = new Date(eYM).toLocaleDateString();
            await this.$store.dispatch('survey/GetProjectList',{userId: getToken(), sDate, eDate, key: key }).then(()=>{ 
                this.searchForm.projectId = ''
            })
        },
        async onAnalisys(){
            if (!this.searchForm.projectId) {
                this.$message.error(`请选择项目`);
            }
            const {projectId,includeTest} = this.searchForm
            // console.log('aaaa',this.searchForm)
            this.init()
            this.activeMediaName ='全媒体'
            this.$nextTick(async () => {
                const toast = this.$loading({
					lock: true,
					text: '分析中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
                
                await this.$store.dispatch('survey/ProjectAnalisysV5',{userId:getToken(), projectId,includeTest}).then(()=>{ 
                    // console.log('aaa',this.isPlan1)
                    setTimeout(() => {
                        var data2 = this.ProjectAnalisysV5[2]
                        var data7 = this.ProjectAnalisysV5[7]
                        var data21 = this.ProjectAnalisysV5[21][0]
                        this.mediaList = this.ProjectAnalisysV5[22]
                        // console.log('data21',data21)
                        if (data21.existPgMt || data21.existPgYx){
                            this.period = '评估'
                        }
                        if (data21.existGhMt || data21.existGhYx){
                            this.period = '规划'
                        }
                        
                        if (data21.existPgYx || data21.existGhYx){
                            // console.log('1')
                            this.type = '营销'
                        }else{
                            if (data21.existPgMt || data21.existGhMt){
                                this.type = '媒体'
                                // console.log('2')
                            }
                        }
                        console.log('1',this.type)

                        var title
                        var x=[],v=[]
                        var siwei = data7.filter(item=>item.type == '四维')
                        // console.log('siwei',siwei)
                        if (siwei?.length>0){
                            console.log('四维')
                            this.siweiSc = true
                            this.siweiPc = true
                            this.siweiQd = true
                            this.siweiZs = true
                            this.siweiNl = true
                            this.siweiJy = true
                            this.siweiQg = true
                            this.siweiZhishu = true

                            this.mediaList.forEach(async eleML => {
                                var pMedia=eleML.parentMedia
                                if (pMedia=='全媒体'){
                                    pMedia=null
                                }
                                var dataByMedia = data2.filter(item=>item.parentMedia == pMedia)
                                console.log('dataByMedia',dataByMedia,pMedia)
                                x=[],v=[]
                                title = `关键${this.type}触点接触时长比较`
                                dataByMedia.sort((a, b) => { return b.sj - a.sj; });
                                dataByMedia.forEach(ele => {
                                    if (ele.sj>0){
                                        x.push(ele.media)
                                        v.push(ele.sj)
                                    }
                                })
                                // this.$refs.siweiSc.initChart(title,'',{x,v},'bar')   
                                eleML.siweiSc = {title,x,v}     
                                // console.log('eleML.siweiSc',eleML.siweiSc)                    

                                x=[],v=[]
                                title = `关键${this.type}触点接触频次比较`
                                dataByMedia.sort((a, b) => { return b.cs - a.cs; });
                                dataByMedia.forEach(ele => {
                                    if (ele.cs>0){
                                        x.push(ele.media)
                                        v.push(ele.cs)
                                    }
                                })
                                // this.$refs.siweiPc.initChart(title,'',{x,v},'bar')    
                                eleML.siweiPc = {title,x,v}                           

                                x=[],v=[]
                                title = `关键${this.type}触点接触强度指数比较`
                                dataByMedia.sort((a, b) => { return b.rate - a.rate; });
                                dataByMedia.forEach(ele => {
                                    if (ele.rate>0){
                                        x.push(ele.media)
                                        v.push(ele.rate)
                                    }
                                })
                                // this.$refs.siweiQd.initChart(title,'',{x,v},'bar')   
                                eleML.siweiQd = {title,x,v}                         


                                var siweiByMedia = siwei.filter(item=>item.parentMedia == pMedia)
                                x=[],v=[]
                                title = `关键${this.type}触点知识维度得分`
                                siweiByMedia.sort((a, b) => { return b.zs - a.zs; });
                                siweiByMedia.forEach(ele => {
                                    if (ele.zs>0){
                                        x.push(ele.media)
                                        v.push(ele.zs)
                                    }
                                })
                                // this.$refs.siweiZs.initChart(title,'',{x,v},'bar')  
                                eleML.siweiZs = {title,x,v}

                                x=[],v=[]
                                title = `关键${this.type}触点能力维度得分`
                                siweiByMedia.sort((a, b) => { return b.nl - a.nl; });
                                siweiByMedia.forEach(ele => {
                                    if (ele.nl>0){
                                        x.push(ele.media)
                                        v.push(ele.nl)
                                    }
                                })
                                // this.$refs.siweiNl.initChart(title,'',{x,v},'bar')           
                                eleML.siweiNl = {title,x,v}                 

                                x=[],v=[]
                                title = `关键${this.type}触点经验维度得分`
                                siweiByMedia.sort((a, b) => { return b.jy - a.jy; });
                                siweiByMedia.forEach(ele => {
                                    if (ele.jy>0){
                                        x.push(ele.media)
                                        v.push(ele.jy)
                                    }
                                })
                                // this.$refs.siweiJy.initChart(title,'',{x,v},'bar')  
                                eleML.siweiJy = {title,x,v}                           

                                x=[],v=[]
                                title = `关键${this.type}触点情感维度得分`
                                siweiByMedia.sort((a, b) => { return b.qg - a.qg; });
                                siweiByMedia.forEach(ele => {
                                    if (ele.qg>0){
                                        x.push(ele.media)
                                        v.push(ele.qg)
                                    }
                                })
                                // this.$refs.siweiQg.initChart(title,'',{x,v},'bar')  
                                eleML.siweiQg = {title,x,v}                             

                                x=[],v=[]
                                title = `关键${this.type}触点效能指数排序`
                                siweiByMedia.sort((a, b) => { return b.zhishu - a.zhishu; });
                                siweiByMedia.forEach(ele => {
                                    if (ele.zhishu>0){
                                        x.push(ele.media)
                                        v.push(ele.zhishu)
                                    }
                                })
                                // this.$refs.siweiZhishu.initChart(title,'',{x,v},'bar')       
                                eleML.siweiZhishu = {title,x,v}   
                            })                   
                            
                            this.handleMediaClick();
                        }

                        var sanli = data7.filter(item=>item.type == '三力')                    
                        if (sanli?.length>0){
                            console.log('三力')
                            this.sanliXs = true
                            this.sanliPp = true
                            this.sanliYh = true
                            this.sanliZhishu = true
                            // console.log('sanli',sanli)
                            x=[],v=[]
                            title = `关键${this.type}触点销售力维度得分`
                            sanli.sort((a, b) => { return b.zs - a.zs; });
                            sanli.forEach(ele => {
                                if (ele.zs>0){
                                    x.push(ele.media)
                                    v.push(ele.zs)
                                }
                            })
                            this.$refs.sanliXs.initChart(title,'',{x,v},'bar')                            

                            x=[],v=[]
                            title = `关键${this.type}触点品牌力维度得分`
                            sanli.sort((a, b) => { return b.nl - a.nl; });
                            sanli.forEach(ele => {
                                if (ele.nl>0){
                                    x.push(ele.media)
                                    v.push(ele.nl)
                                }
                            })
                            this.$refs.sanliPp.initChart(title,'',{x,v},'bar')
                            
                            x=[],v=[]
                            title = `关键${this.type}触点用户力维度得分`
                            sanli.sort((a, b) => { return b.jy - a.jy; });
                            sanli.forEach(ele => {
                                if (ele.jy>0){
                                    x.push(ele.media)
                                    v.push(ele.jy)
                                }
                            })
                            this.$refs.sanliYh.initChart(title,'',{x,v},'bar')
                            

                            x=[],v=[]
                            title = `关键${this.type}触点效能指数排序`
                            if (title == '关键媒体触点效能指数排序'){
                                title = '关键媒体触点影响强度指数排序'
                            }
                            sanli.sort((a, b) => { return b.zhishu - a.zhishu; });
                            sanli.forEach(ele => {
                                if (ele.zhishu>0){
                                    x.push(ele.media)
                                    v.push(ele.zhishu)
                                }
                            })
                            this.$refs.sanliZhishu.initChart(title,'',{x,v},'bar')                            
                        }

                        var nrlx = data7.filter(item=>item.type == '内容类型')                    
                        if (nrlx?.length>0){
                            console.log('内容类型')
                            this.leixingGz = true
                            this.leixingJr = true
                            this.leixingXd = true
                            this.leixingZhishu = true
                            x=[],v=[]
                            title = `关键内容类型关注度维度得分`
                            nrlx.sort((a, b) => { return b.zs - a.zs; });
                            nrlx.forEach(ele => {
                                if (ele.zs>0){
                                x.push(ele.media)
                                v.push(ele.zs)
                                }
                            })
                            this.$refs.leixingGz.initChart(title,'',{x,v},'bar')
                            
                            x=[],v=[]
                            title = `关键内容类型卷入度维度得分`
                            nrlx.sort((a, b) => { return b.nl - a.nl; });
                            nrlx.forEach(ele => {
                                if (ele.nl>0){
                                x.push(ele.media)
                                v.push(ele.nl)
                                }
                            })
                            this.$refs.leixingJr.initChart(title,'',{x,v},'bar')
                            
                            x=[],v=[]
                            title = `关键内容类型预行动维度得分`
                            nrlx.sort((a, b) => { return b.jy - a.jy; });
                            nrlx.forEach(ele => {
                                if (ele.jy>0){
                                x.push(ele.media)
                                v.push(ele.jy)
                                }
                            })
                            this.$refs.leixingXd.initChart(title,'',{x,v},'bar')
                            

                            x=[],v=[]
                            title = `关键内容类型效能指数排序`
                            nrlx.sort((a, b) => { return b.zhishu - a.zhishu; });
                            nrlx.forEach(ele => {
                                if (ele.zhishu>0){
                                x.push(ele.media)
                                v.push(ele.zhishu)
                                }
                            })
                            this.$refs.leixingZhishu.initChart(title,'',{x,v},'bar')                            
                        }

                        var nrfg = data7.filter(item=>item.type == '内容风格')                    
                        if (nrfg?.length>0){
                            console.log('内容风格')
                            this.fenggeGz = true
                            this.fenggeJr = true
                            this.fenggeXd = true
                            this.fenggeZhishu = true
                            x=[],v=[]
                            title = `关键内容风格关注度维度得分`
                            nrfg.sort((a, b) => { return b.zs - a.zs; });
                            nrfg.forEach(ele => {
                                if (ele.zs>0){
                                x.push(ele.media)
                                v.push(ele.zs)
                                }
                            })
                            this.$refs.fenggeGz.initChart(title,'',{x,v},'bar')
                            
                            x=[],v=[]
                            title = `关键内容风格卷入度维度得分`
                            nrfg.sort((a, b) => { return b.nl - a.nl; });
                            nrfg.forEach(ele => {
                                if (ele.nl>0){
                                x.push(ele.media)
                                v.push(ele.nl)
                                }
                            })
                            this.$refs.fenggeJr.initChart(title,'',{x,v},'bar')
                            
                            x=[],v=[]
                            title = `关键内容风格预行动维度得分`
                            nrfg.sort((a, b) => { return b.jy - a.jy; });
                            nrfg.forEach(ele => {
                                if (ele.jy>0){
                                x.push(ele.media)
                                v.push(ele.jy)
                                }
                            })
                            this.$refs.fenggeXd.initChart(title,'',{x,v},'bar')
                            

                            x=[],v=[]
                            title = `关键内容风格效能指数排序`
                            nrfg.sort((a, b) => { return b.zhishu - a.zhishu; });
                            nrfg.forEach(ele => {
                                if (ele.zhishu>0){
                                x.push(ele.media)
                                v.push(ele.zhishu)
                                }
                            })
                            this.$refs.fenggeZhishu.initChart(title,'',{x,v},'bar')
                        }
              
                        
                    },1000)
                    
                    setTimeout(() => {
                        // console.log('siweiSc',this.$refs.siweiSc)
                        this.$refs.siweiSc.resize()
                        this.$refs.siweiPc.resize()
                        this.$refs.siweiQd.resize()
                        this.$refs.siweiZs.resize()
                        // this.$refs.siweiNl.resize()
                        // this.$refs.siweiJy.resize()
                        // this.$refs.siweiQg.resize()
                        // this.$refs.siweiZhishu.resize()
                        this.$refs.sanliXs.resize()
                        this.$refs.sanliPp.resize()
                        this.$refs.sanliYh.resize()
                        this.$refs.sanliZhishu.resize()
                        this.$refs.leixingGz.resize()
                        this.$refs.leixingJr.resize()
                        this.$refs.leixingXd.resize()
                        this.$refs.leixingZhishu.resize()
                        this.$refs.fenggeGz.resize()
                        this.$refs.fenggeJr.resize()
                        this.$refs.fenggeXd.resize()
                        this.$refs.fenggeZhishu.resize()
                    },1000)
                    
                })

                toast.close()
            });
        },

        handleMediaClick(){
            this.mediaList.forEach(async ele => {
                if (ele.parentMedia == this.activeMediaName){
                    // this.curMedia = ele

                    // console.log('ele',ele)
                    document.getElementById('siweiSc').removeAttribute('_echarts_instance_');
                    document.getElementById('siweiPc').removeAttribute('_echarts_instance_');
                    document.getElementById('siweiQd').removeAttribute('_echarts_instance_');
                    document.getElementById('siweiZs').removeAttribute('_echarts_instance_');
                    document.getElementById('siweiNl').removeAttribute('_echarts_instance_');
                    document.getElementById('siweiJy').removeAttribute('_echarts_instance_');
                    document.getElementById('siweiQg').removeAttribute('_echarts_instance_');
                    document.getElementById('siweiZhishu').removeAttribute('_echarts_instance_');

                    this.$nextTick(async () => {
                        this.$refs.siweiSc.initChart(ele.siweiSc.title,'',{x:ele.siweiSc.x,v:ele.siweiSc.v},'bar')
                        this.$refs.siweiPc.initChart(ele.siweiPc.title,'',{x:ele.siweiPc.x,v:ele.siweiPc.v},'bar')
                        this.$refs.siweiQd.initChart(ele.siweiQd.title,'',{x:ele.siweiQd.x,v:ele.siweiQd.v},'bar')
                        this.$refs.siweiZs.initChart(ele.siweiZs.title,'',{x:ele.siweiZs.x,v:ele.siweiZs.v},'bar')
                        this.$refs.siweiNl.initChart(ele.siweiNl.title,'',{x:ele.siweiNl.x,v:ele.siweiNl.v},'bar')
                        this.$refs.siweiJy.initChart(ele.siweiJy.title,'',{x:ele.siweiJy.x,v:ele.siweiJy.v},'bar')
                        this.$refs.siweiQg.initChart(ele.siweiQg.title,'',{x:ele.siweiQg.x,v:ele.siweiQg.v},'bar')
                        this.$refs.siweiZhishu.initChart(ele.siweiZhishu.title,'',{x:ele.siweiZhishu.x,v:ele.siweiZhishu.v},'bar')

                        this.$refs.siweiSc.resize()
                        this.$refs.siweiPc.resize()
                        this.$refs.siweiQd.resize()
                        this.$refs.siweiZs.resize()
                        this.$refs.siweiNl.resize()
                        this.$refs.siweiJy.resize()
                        this.$refs.siweiQg.resize()
                        this.$refs.siweiZhishu.resize()

                    })
                }
            });   
        },

        init(){
            this.graphData = []
            this.siweiSc = false
            this.siweiPc = false
            this.siweiQd = false
            this.siweiZs = false
            this.siweiNl = false
            this.siweiJy = false
            this.siweiQg = false
            this.siweiZhishu = false
            this.sanliXs = false
            this.sanliPp = false
            this.sanliYh = false
            this.sanliZhishu = false
            this.leixingGz = false
            this.leixingJr = false
            this.leixingXd = false
            this.leixingZhishu = false
            this.fenggeGz = false
            this.fenggeJr = false
            this.fenggeXd = false
            this.fenggeZhishu = false
        },
        onDateChange(obj){
            this.onSearch();
        },

        async onExport(){
            if (this.searchForm.projectId) {
                this.excelExporting = true
                await UserQuesExport({userId: getToken(), projectId:this.searchForm.projectId}).then(()=>{
                    // loading.close();
                    this.excelExporting = false
                })
            }            
        },
        async onDetailExport(){
            if (this.searchForm.projectId) {
                this.excelExporting = true
                await GetUserAnswer({userId: getToken(), projectId:this.searchForm.projectId}).then(()=>{
                    this.excelExporting = false
                })
            }   
        },
        async onPPTExport(){
            if (this.searchForm.projectId) {
                this.pptExporting = true
                await ProjectExportPPT({userId: getToken(), projectId:this.searchForm.projectId,includeTest:this.searchForm.includeTest}).then((res)=>{
                    window.open(res.data)
                    this.pptExporting = false
                })
            }
        },
        async onSpssExport(){
            if (this.searchForm.projectId) {
                this.spssExporting = true
                await CreateSPSSData({userId: getToken(), projectId:this.searchForm.projectId}).then(()=>{
                    this.spssExporting = false
                })
            }   
        }
        
    }
}
</script>
 
<style  lang="scss">
.el-table th {
    background-color: #edf6ff !important;
}
.el-table thead {
    color: #fff;
}
.chartContainer {
    // position: relative;
    // .label {
    //   position: absolute;
    //   top: 60px;
    //   right: 160px;
    //   div {
    //     padding: 4px 0;
    //     font-size:x-small;
    //     .hori-line {
    //       display: inline-block;
    //       width: 200px; // 指定固定宽度
    //       border-top: solid #e0e0e0 1px;
    //       vertical-align: middle;
    //       margin-right: 2px;
    //     }
    //   }
    // }
}
.row1{
    background-color: #B39B68;
}
.row2{
    background-color: #C4BD97;
}
.row3{
    background-color: #DEDAC3;
}
.el-dropdown-menu{
    display:none
}
.dropright:hover > .dropdown-menu {
    display:block;
}
// .el-dropdown-link {
//     cursor: pointer;
//     color: #409EFF;
//   }
//   .el-icon-arrow-down {
//     font-size: 12px;
//   }
.submenu>>>.el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu.is-active .el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu__title{
    padding: 0px;
}
.my-table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin-left:20px;
}
.my-table td {

}
table tr:nth-child(2n) {
    background: #fff;
}     
table tr:nth-child(2n+1) {
    background: #f0f6fc;
}
.relaTable:nth-child(2n+1) {
    background: #fff;
    padding:5px;
}
.relaTable:nth-child(2n) {
    background: #edf6ff;
    padding:5px;
}
.relaTable2:nth-child(2n+1) {
    background: #fff;
    padding:5px;
}
.relaTable2:nth-child(2n) {
    background: #e4e5e4;
    padding:5px;
}
</style>
