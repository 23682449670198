<template>  
    <!-- <div class="echart" :id="objName" :style="{float:'left',width: '100%', height: '600px'}"></div>    -->
    <div class="echart" :id="objName" :style="style"></div>   
</template>

<script>

import echarts from  "echarts";

export default {
  props: {
        objName: {
            type: String,
            default: "echart-line"
        },
        style:{
            type:Object,
            default:{float:'left',width: '100%', height: '500px'}
        },
      //   seriesType:{
      //       type:String,
      //       default:'pie'
      //   }
    },
    methods:{

    initChart(title,subtitle,data,seriesType="bar",convert=false) {       
        let getchart = echarts.init(document.getElementById(this.objName));
        console.log('jinru chart',data,this.objName)

        var option = {
            title: {
                text: title,
                subtext: subtitle,
                left: 'center'
            },
            tooltip: {
                confine: true
            },
            // grid: {
            //     left: '3%',
            //     right: '45%',
            //     bottom: '3%',
            //     containLabel: true
            // },
            color: ['#AB8B55'],            
            series: [
                {
                    barMaxWidth: 55,
                    data: data.v,//[120, 200, 150, 80, 70, 110, 130],
                    type: seriesType,
                    showBackground: true,
                    label: {
                        normal: {
                            show: true,
                            position: 'right',//'inside',//
                            // rotate: 45,
                            textStyle:{
                                color: '#000000'                                
                            },
                            formatter:function(param){
                                
                                // var res =  "{xing|} " + param.name + param.value
                                var res =  param.name + "   " + param.value
                                // if (param.value >= 90) {
                                //     res = res + '  A  必选媒体'
                                // } else if (param.value >= 80) {
                                //     res = res + '  B  建议选择'
                                // } else if (param.value >= 70) {
                                //     res = res + '  C  可供选择'
                                // } else {
                                //     res = res + '  D  长尾触点'
                                // }
                                // console.log('aaa',res)
                                return res;
                            },
                        }
                    },
                    itemStyle: {
                        normal: {
                            color: function (params) {
                            //根据两个参数判断圆柱体的显示颜色，也可以用数组形式使用多个颜色
                            // console.log('params',params)
                            let colorArr =
                                params.data >= 90 ? "#B39B68" : params.data >= 80 ? "#C4BD97" : params.data >= 70 ? '#DEDAC3' : '#EEEDE1';
            
                            return colorArr;
                            },
                        },
                        // emphasis: {
                        //     color: 'red' // 鼠标悬停颜色
                        // }
                    },
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    }
                }
            ]
        };

        if (!convert) {
            option.xAxis = {
                type: 'category',
                data: data.x//['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            option.yAxis = {
                type: 'value'
            }
        } else {
            option.xAxis = {
                // type: 'category',
                // data: data.x//['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                type: 'value'
            },
            option.yAxis = {
                // type: 'value'
                data: data.x,
                axisLabel: {
                    show: false
                //     textStyle: {
                //         fontSize: 10
                //     },
                //     formatter: (val) => {
                //     let c = document.createElement("canvas");
                //     const ctx = c.getContext("2d");
                //     ctx.font = "16px normal"; // 设置画布内的字体，与设置的textStyle一致
                //     const arr = val.split("");
                //     arr.map((item) => ctx.measureText(item).width)
                //         .reduce((pre, next, index) => {
                //         const nLen = pre + next;
                //         if (nLen > 200) {
                //             arr[index - 1] += "\n";
                //             return next;
                //         } else {
                //             return nLen;
                //         }
                //         });
                //     c = null;
                //     return arr.join("");
                //     }
                }
      
            }
        }
        

        getchart.setOption(option);

        //随着屏幕大小调节图表
        window.addEventListener("resize", () => {
            getchart.resize();
        });
      
    //   const _this = this;

    //   getchart.on('click',function(e){
    //       // console.log(e);
    //       var obj={objName:_this.objName,value:e.name}
    //       _this.$emit('onchange',obj);
          
    //   })
    


        
    },

  }

}
</script>