<template>  
    <!-- <div class="echart" :id="objName" :style="{float:'left',width: '100%', height: '600px'}"></div>    -->
    <div class="echart" :id="objName" :style="style"></div>   
</template>

<script>

import echarts from  "echarts";

export default {
  props: {
        objName: {
            type: String,
            default: "echart-line"
        },
        style:{
            type:Object,
            default:{float:'left',width: '100%', height: '500px'}
        },
      //   seriesType:{
      //       type:String,
      //       default:'pie'
      //   }
    },
    methods:{

    initChart(title,subtitle,data,seriesType="bar",convert=false) {       
        let getchart = echarts.init(document.getElementById(this.objName));
        console.log('jinru chart',data,this.objName)

        var option = {
            title: {
                text: title,
                subtext: subtitle,
                left: 'center'
            },
            tooltip: {
                confine: true
            },
            color: ['#3f75ea'],            
            series: [
                {
                    barMaxWidth: 55,
                    data: data.v,//[120, 200, 150, 80, 70, 110, 130],
                    type: seriesType,
                    showBackground: true,
                    label: {
                        show: true, // 显示值
                        position: 'top', // 值的位置
                        // 可以通过 formatter 自定义显示格式
                        formatter: '{c}' // {c} 表示数据值
                    },
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    },
                    itemStyle: {
                        // normal: {
                        //     color: function (params) {
                        //     //根据两个参数判断圆柱体的显示颜色，也可以用数组形式使用多个颜色
                        //     // console.log('params',params)
                        //     let colorArr =
                        //         params.data >= 90 ? "#B39B68" : params.data >= 80 ? "#C4BD97" : params.data >= 70 ? '#DEDAC3' : '#EEEDE1';
            
                        //     return colorArr;
                        //     },
                        // },
                        color: '#81a1eb',
                        emphasis: {
                            color: '#4a7deb' // 鼠标悬停颜色
                        }
                    }
                }
            ]
        };

        if (!convert) {
            option.xAxis = {
                type: 'category',
                data: data.x,//['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                axisLabel: {
                    show: true,
                    interval:0,
                    // rotate: 40    // 设置x轴标签旋转角度

                    // 直接竖排
                    // formatter:function(value)
                    // {
                    //     return value.split("").join("\n");
                    // }

                    // 一行2字
                    // formatter:function(value)
                    // {
                    //     // debugger
                    //     var ret = "";//拼接加\n返回的类目项
                    //     var maxLength = 2;//每项显示文字个数
                    //     var valLength = value.length;//X轴类目项的文字个数
                    //     var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                    //     if (rowN > 1)//如果类目项的文字大于3,
                    //     {
                    //         for (var i = 0; i < rowN; i++) {
                    //             var temp = "";//每次截取的字符串
                    //             var start = i * maxLength;//开始截取的位置
                    //             var end = start + maxLength;//结束截取的位置
                    //             //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    //             temp = value.substring(start, end) + "\n";
                    //             ret += temp; //凭借最终的字符串
                    //         }
                    //         return ret;
                    //     }
                    //     else {
                    //         return value;
                    //     }
                    // }

                    // 隔一个换行
                    formatter : function(params,index){
                        if (index % 2 != 0) {
                            return '\n\n' + params;
                        }
                        else {
                            return params;
                        }
                    }
                }
            },
            option.yAxis = {
                type: 'value',
                axisLabel: {
                    show: false,
                    // rotate: 5    // 设置x轴标签旋转角度
                },
                splitLine: {
                    show: false // 取消 x 轴网格线
                }
                // axisLabel : {
                //     interval: 0,//横轴信息全部显示
                //     rotate: -45,//旋转45度显示
                //     formatter:function(value){  //换行显示
                //         return value.split("").join("\n");  
                //     }, 
                //     textStyle: {
                //         color: '#03b3ef'
                //     }
                // }
            }
        } else {
            option.xAxis = {
                // type: 'category',
                // data: data.x//['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                type: 'value',
                axisLabel: {
                    show: true,
                    rotate: 5    // 设置x轴标签旋转角度
                }
            },
            option.yAxis = {
                // type: 'value'
                data: data.x,
                axisLabel: {
                    textStyle: {
                        fontSize: 10
                    },
                    rotate: 75,//旋转45度显示
                    formatter: (val) => {
                        let c = document.createElement("canvas");
                        const ctx = c.getContext("2d");
                        ctx.font = "16px normal"; // 设置画布内的字体，与设置的textStyle一致
                        const arr = val.split("");
                        arr.map((item) => ctx.measureText(item).width)
                            .reduce((pre, next, index) => {
                            const nLen = pre + next;
                            if (nLen > 200) {
                                arr[index - 1] += "\n";
                                return next;
                            } else {
                                return nLen;
                            }
                            });
                        c = null;
                        return arr.join("");
                    }
                }      
            }
        }        

        getchart.setOption(option);
        // console.log('getchart',getchart)
        getchart.resize();
        //随着屏幕大小调节图表
        window.addEventListener("resize", () => {
            getchart.resize();
        });
        // window.onresize = getchart.resize;
    //   const _this = this;

    //   getchart.on('click',function(e){
    //       // console.log(e);
    //       var obj={objName:_this.objName,value:e.name}
    //       _this.$emit('onchange',obj);
          
    //   })
    


        
    },

    resize(){
        let getchart = echarts.init(document.getElementById(this.objName));
        getchart.resize()
    }

  }

}
</script>